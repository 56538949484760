// External modules
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

// App module
import { AppModule } from "./app/app.module";

// Environment
import { environment } from "./environments/environment";


// Check env for production
if (environment.production) {
    // Enable production mode
    enableProdMode();
}

// Bootstrap application
document.addEventListener('DOMContentLoaded', () => {
    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.error(err));
});