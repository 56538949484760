// External modules
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

// Serialize hook
interface ISerializeHook {
    (): Promise<void> | void
}

// Request config
interface IRequestConfig {
    beforeSerialize?: ISerializeHook;
    afterSerialize?: ISerializeHook;
    forceReload?: boolean;
}

// Http method type
type HttpMethod = "get" | "post" | "delete" | "put";

/**
 * Base service
 * @description Base service for communication
 * with external API
 */
@Injectable({ providedIn: "root" })
export abstract class BaseService {


    /**
     * Http error response
     * @description Error response observable
     */
    private static readonly httpErrorResponseSource: Subject<HttpErrorResponse> = new Subject<HttpErrorResponse>();
    public static readonly httpErrorResponse$: Observable<HttpErrorResponse> = BaseService.httpErrorResponseSource.asObservable();

    /**
     * Constructor
     * @param http 
     */
    constructor(
        protected readonly http: HttpClient
    ) { }

    /**
     * Make Http request
     * @param method 
     * @param url 
     * @param params 
     * @param payload 
     * @param config
     * @returns 
     */
    protected async request<TResponse>(method: HttpMethod, url: string, params?: any, payload?: any): Promise<TResponse> {
        // Init http options
        const options = {
            params: params ? this.getHttpParams(params) : undefined,
            body: undefined as any
        };

        // Check for payload
        if (payload) {
            try {
                // Serialize payload to json
                options.body = this.serializePayloadToJSON(payload);
            }
            catch (e) {
                // Log error
                console.error(e);

                // TODO
                throw e;
            }
        }        

        try {
            // Make request
            const response: HttpResponse<string> = await this.http.request(method, url, {
                ...options,
                observe: "response",
                responseType: "text",
                withCredentials: true
            }).toPromise();

            // Check body
            if (!response.body) {
                // Nothing to process
                return null;
            }

            // Parse response data
            const data = this.parseJSONResponse<TResponse>(response.body);

            // Return result
            return data;
        }
        catch (e) {
            // Get typed error
            const error: HttpErrorResponse = e;

            // Emit error
            BaseService.httpErrorResponseSource.next(e);

            // Check code
            if (error.status <= 400) {
                // Parse response and return it
                return this.parseJSONResponse(error.error);
            }
            else {
                // Rethrow exception
                e.error = this.parseJSONResponse(error.error);
                throw e;
            }
        }
    }

    /**
     * Get http params
     * @param params 
     */
    protected getHttpParams(params: any): HttpParams {
        // Init params
        let httpParams = new HttpParams();

        // Iterate params object
        Object.keys(params).filter((key) => params[key] != null).map((key) => {
            // Get value
            const value = params[key];

            // Add param to http params
            httpParams = httpParams.set(key, value instanceof Object ? JSON.stringify(value) : value);
        });

        // Return result
        return httpParams;
    }

    /**
     * Parse json response
     * @param json 
     * @returns 
     */
    protected parseJSONResponse<TResponse>(json: string): TResponse {
        try {
            // TODO date parsing
            return JSON.parse(json);
        }
        catch (e) {
            // Return plain json
            return json as any;
        }
    }

    /**
     * Serialize payload to JSON
     * @param payload 
     */
    protected serializePayloadToJSON(payload: any): string {
        // Stringify payload
        return JSON.stringify(payload, (name, value) => {
            // Check for parent optional value
            if (name === "parent" && value)
                return undefined;

            // Check for active optional value
            if (name === "active" && value)
                return undefined;

            // Check for boolean
            if (typeof value === "boolean" && value == false)
                return false;

            // Check for empty value
            if (value === "")
                return null;

            // Return value
            return value;
        });
    }
}