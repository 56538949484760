// External modules
import { Toast as NgxToast } from "ngx-toastr";
import { Component } from "@angular/core";

@Component({
    selector: ".etiplus-toast",
    templateUrl: "./toast.component.html",
    styleUrls: ["./toast.component.scss"]
})
export class Toast extends NgxToast {

    /**
     * On action click
     * @param event 
     */
    public onActionClick(event: Event): void {
        // Prevent event propagation
        event.stopPropagation();

        // Execute action
        this.executeAction();
    }

    /**
     * Execute action
     */
    private async executeAction(): Promise<void> {
        // Trigger action
        this.toastPackage.triggerAction();
    }
}