// External modules
import { Component, OnDestroy, OnInit } from "@angular/core";
import { HttpStatusCode } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { filter } from "rxjs/operators";

// Services
import { BaseService } from "src/services/base.service";

// Utilities
import { Subscriber } from "src/utility/subscriber";

@Component({
    selector: "organigram-app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {

    // Init local subscriber
    private readonly subscriber: Subscriber = new Subscriber();

    /**
     * Constructor
     * @param router 
     * @param clipboard 
     * @param toaster 
     * @param translate 
     * @param cookie 
     * @param tawkTo
     */
    constructor(
        private readonly router: Router,
        private readonly toaster: ToastrService,
    ) {
        // Register to server errors
        this.registerToServerErrorResponse();
        // Register to forbidden access response
        this.registerToForbiddenAccessResponse();
    }

    /**
     * On init hook
     */
    public ngOnInit(): void {
    }

    /**
     * On destroy hook
     * @description Angular hook called
     * when instance is being destroyed
     */
    public ngOnDestroy(): void {
        // Clear subscriptions
        this.subscriber.clear();
    }

    /**
     * Register to forbidden access response
     * @description Handle forbidden access response, meaning
     * redirect use to login page and show notification if 
     * required.
     */
    private async registerToForbiddenAccessResponse(): Promise<void> {
        // Register to forbidden http error response 
        this.subscriber.register("http:response:forbidden", BaseService.httpErrorResponse$.pipe(filter((response) => response.status === HttpStatusCode.Forbidden)).subscribe(async (response) => {
            // Redirect to login page
            await this.router.navigate(["/login"], { queryParams: { "redirect": this.router.url } });
        }));
    }

    /**
     * Register to server error response
     * @description Handle server error response,
     * showing notification to the user that something
     * is broken and it is not clients fault
     */
    private async registerToServerErrorResponse(): Promise<void> {
        // Register to server http error response 
        this.subscriber.register("http:response:server-error", BaseService.httpErrorResponse$.pipe(filter((response) => response.status >= HttpStatusCode.InternalServerError)).subscribe(async (response) => {
            // Show error
            this.toaster.error("Nelze se připojit k serveru, zkuste to prosím později.", "Chyba serveru");
        }));
    }
}