<div class="etiplus-item">
    <div class="etiplus-item__body">
        <div class="etiplus-item__icon etiplus-item__icon--success">
            <span class="material-icons-outlined"></span>
        </div>
        <div class="etiplus-item__content">
            <div class="etiplus-toast-title" [attr.aria-label]="title">
                {{ title }} <ng-container *ngIf="duplicatesCount">[{{ duplicatesCount + 1 }}]</ng-container>
            </div>
            <div class="etiplus-toast-message" role="alertdialog" aria-live="polite" [attr.aria-label]="message">
                {{ message }}
            </div>
        </div>
    </div>
</div>