// External modules
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser"
import { HttpClientModule } from "@angular/common/http";
import { ToasterModule } from "src/utility/toaster";
import { NgModule } from "@angular/core";
import { SortablejsModule } from "src/utility/ngx-sortablejs/sortablejs.module";

// Routing
import { AppRoutingModule } from "./app.routing";

// Components
import { AppComponent } from "./app.component";


@NgModule({
    imports: [
        ToasterModule,
        BrowserModule.withServerTransition({ appId: "serverApp" }),
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SortablejsModule.forRoot({ animation: 150 })
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    providers: []
})
export class AppModule { }