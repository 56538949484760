// External modules
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

// Routes
const routes: Routes = [
    {
        path: "",
        loadChildren: () => import("./pages/main/main.module").then((m) => m.MainPageModule)
    },
    {
        path: "login",
        loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginPageModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        paramsInheritanceStrategy: "always"
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }