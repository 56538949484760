// External modules
import { NgModule } from "@angular/core";
import { ToastrModule } from "ngx-toastr";

// Components
import { Toast } from "./components/toast/toast.component";

@NgModule({
    imports: [ToastrModule.forRoot({
        toastComponent: Toast
    })],
    declarations: [Toast],
    exports: [ToastrModule]
})
export class ToasterModule { }